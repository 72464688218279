<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row EDC-DashboardRowSpacing" style="padding-left:8px !important;padding-top:16px !important;padding-bottom:16px !important;">
            <v-col cols="2"  class="EDC-Col" align="left">
                Create Business view For
            </v-col>
            <v-col cols="10" class="EDC-Col">
            </v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="1"></v-col>

            <v-col class="EDC-Col" cols="3">
                <svgicon :class="{'svg-icon-h1':true, 'svg-fill-h1':true, 'svg-h1-border':bv_selected_for!='BO' , 'svg-h1-border-highlight':bv_selected_for==='BO'}" name="business_object_v2"  :original="true" title="Business Object" @mouseover.native="bv_selected_for='BO'" @click.native="redirectTOBv()"></svgicon>
            </v-col>
            <v-col class="EDC-Col EDC-DashboardRowSpacing" cols="3">
                <svgicon :class="{'svg-icon-h1':true, 'svg-fill-h1':true, 'svg-h1-border':bv_selected_for!='DM' , 'svg-h1-border-highlight':bv_selected_for==='DM'}" name="datamap_v2"  :original="true" title="Datamap" @mouseover.native="bv_selected_for='DM'" @click.native="redirectTOBv()"></svgicon>
            </v-col>
            <v-col class="EDC-Col" cols="3">
                <svgicon :class="{'svg-icon-h1':true, 'svg-fill-h1':true, 'svg-h1-border':bv_selected_for!='T' , 'svg-h1-border-highlight':bv_selected_for==='T'}" class="svg-icon-h1 svg-fill-h1" name="Database_Table"  :original="true" title="Table" @mouseover.native="bv_selected_for='T'" @click.native="redirectTOBv()"></svgicon>
            </v-col>
            <!-- <v-col class="EDC-Col" cols="1"></v-col> -->
        </v-row>
        <v-row class="EDC-Row EDC EDC-DashboardRowSpacing">
            <v-col class="EDC-Col" cols="1"></v-col>

            <v-col class="EDC-Col" cols="3">
               <span :class="{'normal-font':bv_selected_for!='BO' , 'bold-font':bv_selected_for==='BO'}"> Business Object </span>
            </v-col>
            <v-col class="EDC-Col" cols="3">
                <span :class="{'normal-font':bv_selected_for!='DM' , 'bold-font':bv_selected_for==='DM'}"> Datamap </span>
            </v-col>
            <v-col class="EDC-Col" cols="3">
                <span :class="{'normal-font':bv_selected_for!='T' , 'bold-font':bv_selected_for==='T'}"> Table </span>
            </v-col>
            <!-- <v-col class="EDC-Col" cols="1"></v-col> -->
        </v-row>
    </v-container>
</template>
<style scoped>
    /* radio label styling */
    .radioClass >>> label{
        top: 5px !important;
        /* margin: 7px !important; */
    }
    #flatteningdiv .v-label {
    margin-bottom:0.0rem !important;
    }

    .bold-font {
        font-size: 16px;
        font-weight: bold;
    }

    .normal-font {
        font-size: 12px;
        font-weight: normal;
    }
</style>

<script>
import config from "../../config.json";
import { post as postToServer } from './../../methods/serverCall.js';
import {GET_PROCESS_GRAPH_DATA} from '../../data/url_constants.js';
import {
		CHECKBOX_COLOR,COLOR_CODE
    } from "@/data/macros.js";

export default {
	data(){
			return {
                business_view_for_list: [{
						id: "BO",
						name: "Business Object"
					},
					{
						id: "DM",
						name: "Datamap"
					},
					{
						id: "T",
						name: "Table"
					}
                ],
                bv_selected_for:"BO",
                isReadOnly:false,
                checkboxColor: CHECKBOX_COLOR,
                bv_id:this.$route.params.business_view_id,
                object_revision: this.$route.params.object_version,
                redirectionParams:this.$route.params
            }
    },
    computed:{
        isBVforEdit(){
            return true && this.bv_id
        }
    },
    mounted(){
        var client_id = this.$session.get("client_id")
        if(!this.object_revision)
            this.object_revision = "1"
        if(this.isBVforEdit){
            this.bv_selected_for = ''
            this.redirectTOBv()
        }
    },
    methods:{
        redirectTOBv(){
            if(this.isBVforEdit)
                this.getBusinessViewDetails()
            else{
                this.routeToURL()
            }
        },
        getBusinessViewDetails(){
			var _this = this;
			_this.loader = true
			var data = {
				object_id: this.bv_id,
				object_revision: _this.object_revision,
				object_type_code: 'BV',
				env_id: _this.$session.get('last_selected_env_id'),
				env_name: _this.$session.get('last_selected_env_name'),
                "permission": "R",
				"check_security":true
			};
            Object.assign(data,{"user_id":this.$session.get("user_id"),"user_name":this.$session.get("email")})
			postToServer(
				_this,
				config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
				data
			).then(response => {
                _this.loader = false
                 // _this.object_revision= _this.bv_version ? _this.bv_version : '1',
                 console.log("response=>",response)
                _this.bv_selected_for = response.object_json.selected_object_type_code
                _this.routeToURL()
                
			}).catch(error=>{
				_this.loader = false
				alert(error)
			})
		},
        routeToURL(){
            this.redirectionParams['bv_for']=this.bv_selected_for
            if(this.bv_selected_for === "T"){
                this.$router.push({ name: 'businessviewdesigner', params:this.redirectionParams})
            }
            else{
                this.$router.push({ name: 'businessviewbybodm', params:this.redirectionParams})
            }
        }
    }
}
</script>
