<template>
	<div >
		<v-row class="EDC-Row" no-gutters justify="center" v-if="pdjson.object_name">
			<v-col cols="10" class="EDC-Col">
			</v-col>
			<v-col cols="2" class="EDC-Col" align="end" style="padding-right:22px !important;">
				<vc-button itemText="Print" @click.native="printObj" v-if="pdjson.object_name"></vc-button>
				<vc-button itemText="Close" @click.native="closeTab"></vc-button>
			</v-col>
		</v-row>
		<v-row class="EDC-Row" no-gutters justify="center" id="printdiv" ref="printdiv">
			<v-col cols="12" class="EDC-Col" v-if="pdjson.object_name" justify="center" start="center">
				<div style="overflow-x:auto;">
				<table id="objtable">
					<tr>
						<th>
							Process Definition Name: {{pdjson.object_name}}
						</th>
						
						<th>
							Version: {{pdjson.object_revision}}
						</th>
					</tr>	
					<tr v-if="policyDetails">
						<th colspan="2">Policy Details: {{policyDetails}}</th>
						
					</tr>
					<tr v-for="(step,stepindex) in pdjson.object_json.cells" :key="'step'+stepindex" >
						<td colspan="2" v-if="step.type ==='standard.BorderedImage'">
							<table id="objtable" class="childtbl">
								<tr>
									<th>Name </th>
									<th>[{{stepindex+1}}] {{step.stepname}}</th>
									<th>Type: {{step.steptype}}</th>
								</tr>	
								<tr v-if="step.steptype && dataSelectionSteps.indexOf(step.steptype.toLowerCase()) > -1">
									<th class="firstSubHeader">Selected Tables</th>
									<td colspan="2"> {{getDraggedtableList(step.step_data.select_table_list,pdjson.object_json.cells)}}</td>
								</tr>
								
								
								 <tr v-if="step.steptype && dataSelectionSteps.indexOf(step.steptype.toLowerCase()) > -1" style="width:100% !important;">
									<th class="firstSubHeader">Output Columns</th>
									<td colspan="2" class="bigtd" :title="getOutputColumns(step.step_data.selection,pdjson.object_json.cells)"> 
										{{getOutputColumns(step.step_data.selection,pdjson.object_json.cells)}}</td>
								</tr>


								<tr v-if="step.steptype && dataSelectionSteps.indexOf(step.steptype.toLowerCase()) > -1">
									<th class="firstSubHeader">Distinct</th>
									<td colspan="2"> {{getDistinct(step.step_data.distinct)}}</td>
								</tr>
								<tr>
									<th class="firstSubHeader">Filters</th>
									<td colspan="2"> 
										<table id="objtable" class="childtbl" v-if="step.step_data.filter && step.step_data.filter.length">
											<tr>
												<th>(</th>
												<th>Column</th>
												<th>Operator</th>
												<th>Value</th>
												<th>)</th>
												<th>Operation</th>
											</tr>
											<tr v-for="(filter,fitlerindex) in step.step_data.filter" :key="'filter'+stepindex+fitlerindex">
												<td>{{filter.s_parentheses}}</td>
												<td>{{getFilterColumn(filter,pdjson.object_json.cells)}}</td>
												<td>{{getOperatorText(filter.operator)}}</td>
												<td>{{getValueText(filter)}}</td>
												<td>{{filter.e_parentheses}}</td>
												<td>{{getOperation(filter.operation)}}</td>
											</tr>
										</table>
										<table id="objtable" class="childtbl" v-else-if="step.step_data.drv_table && step.step_data.drv_table.filter && step.step_data.drv_table.filter.length">
											<tr>
												<th>(</th>
												<th>Column</th>
												<th>Operator</th>
												<th>Value</th>
												<th>)</th>
												<th>Operation</th>
											</tr>
											<tr v-for="(filter,fitlerindex) in step.step_data.drv_table.filter" :key="'filter'+stepindex+fitlerindex">
												<td>{{filter.s_parentheses}}</td>
												<td>{{getFilterColumn(filter,pdjson.object_json.cells)}}</td>
												<td>{{getOperatorText(filter.operator)}}</td>
												<td>{{getValueText(filter)}}</td>
												<td>{{filter.e_parentheses}}</td>
												<td>{{getOperation(filter.operation)}}</td>
											</tr>
										</table>
										<span v-else>N/A</span>
									</td>
								</tr>	
								
								<tr v-if="step.steptype && dataSelectionSteps.indexOf(step.steptype.toLowerCase()) > -1 && step.step_data.revisedjoin && step.step_data.revisedjoin.length">
									<th class="firstSubHeader">Joins</th>
									<td colspan="2"> 
										<table id="objtable" class="childtbl">
											<tr v-for="(join,joinindex) in step.step_data.revisedjoin" :key="'join'+stepindex+joinindex">
												<th>Join: {{joinindex+1}}</th>
												<td>
													<table id="objtable" class="childtbl" style="table-layout:fixed !important;">
														<tr>
															<th :title="join.jfrom">{{join.jfrom}}</th>
															<th>{{join.type}}</th>
															<th :title="join.jto">{{join.jto}}</th>
														</tr>
														<tr v-for="(cond,condindex) in join.condition" :key="'cond'+stepindex+joinindex+condindex">
															<td>{{cond.from_column}}</td>
															<td>{{getOperatorText(cond.operator)}}</td>
															<td>{{cond.to_column}}</td>
														</tr>
													</table>
												</td>
											</tr>
											<tr>
												
											</tr>
										</table>
									</td>
									
								</tr>
								<tr v-if="step.steptype && businessSteps.indexOf(step.steptype.toLowerCase()) > -1">
									<th>Limit</th>
									<td colspan="2">{{step.step_data.limit}}</td>
								</tr>
								<tr v-if="step.steptype && (businessSteps.indexOf(step.steptype.toLowerCase()) > -1 || step.steptype.toLowerCase() === 'procedure')">
									<th>On Target</th>
									<td colspan="2">{{step.step_data.run_on_target}}</td>
								</tr>
									
								<tr v-if="step.steptype && businessSteps.indexOf(step.steptype.toLowerCase()) > -1">
									<th style="vertical-align:top;">Business Obj</th>
									<td colspan="2">
										<div>
										<edc-boview :name="'bo'+stepindex" :key="'keybo'+stepindex" :hideJFromFilter='true' :hideJToFilter="true" :hideRelFilter="true" :hideHeading="true" :objectData="selectedBos[step.bus_id]"></edc-boview></div>
									</td>

								</tr>
								<tr v-if="step.steptype && step.steptype.toLowerCase() === 'procedure'">
									<th style="vertical-align:top;">Params</th>
									<td colspan="2">
										<table id="objtable" class="childtbl">
											<tr>
												<th>Parameter</th>
												<th>Value</th>
												<th>In/Out</th>
											</tr>
											<tr v-for="(params,paramindex) in step.step_data.params" :key="'params'+stepindex+paramindex">
												<td>{{params.Parameter_name}}</td>
												<td>{{params.value}}</td>
												<td>{{params.Is_output}}</td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</td>
					</tr>				
				</table>
				</div>
			</v-col>
		</v-row>
		<loading-panel :loader="loader"></loading-panel>
	</div>
</template>
<script>
  import config from '../config.json'
  import {VALUE_TO_TEXT} from '@/methods/flowcharts/constants.js'
  import { shapes, dia } from 'jointjs'
  import {post as postToServer } from '../methods/serverCall.js';
  import objectBOView from './tabularBusinessObjView.vue'
  import * as graphObj from "@/methods/edcGraph/graph.js"
   import {mapActions} from 'vuex'
  export default {
	name:'objectTabularView',
	components:{
		'edc-boview':objectBOView,
	},
	data(){
		return{
			pdjson:{"object_json":{}},
			policyDetails:null,
			commitSize:null,
			dataSelectionSteps:['selection','merge','minus'],
			loader:false,
			businessSteps:['archival','purge','copy'],
			selectedBos:{},
            objectdetails:{}
	  	}
	},
    mounted(){
		this.setDesignerFor({"designerFor":'Process Definition In Tabular Format'})
		this.objectdetails = this.$route.params.object_details
    	this.getObject(_.cloneDeep(this.objectdetails))
    },
    methods:{
		...mapActions(['setDesignerFor']),
    	manageJoins(){
    		for(var i=0;i<this.pdjson.object_json.cells.length;i++){
    			let step = this.pdjson.object_json.cells[i]
    			if((step.type != 'standard.BorderedImage' && step.type!='basic.Image') || this.dataSelectionSteps.indexOf(step.steptype.toLowerCase()) < 0)
    				continue
    			if(step.step_data.join && step.step_data.join.length===0)
    				continue
    			step.step_data.revisedjoin=[]
    			for(var j=0;j<step.step_data.join.length;j++){
    				let join = step.step_data.join[j]
    				let keys = _.keysIn(join)
    				step.step_data.revisedjoin.push(join[keys[0]])
    			}
    		}
    	},
    	getDistinct(distinct){
    		if(distinct)
    			return "True"
    		else
    			""
    	},
    	getRevisedWorkTable(steplist,worktablename){
    		// for(var i=0;i<steplist.length;i++){
    		// 	if(steplist[i].stepname === worktablename)
    		// 		return 'WT_'+(i+1)
    		// }
    		// return 'NA'
    		return worktablename
    	},
    	getDraggedtableList(table_list,allsteps){
    		var tblString=''
    		for (var i = 0; i < table_list.length; i++) {
    			if(tblString)
    				tblString = tblString +','
    			// let tblname= this.getRevisedWorkTable(allsteps,table_list[i])
    			// if(tblname && tblname!='NA')
    			// 	tblString = tblString + table_list[i]+' ('+tblname+')'
    			// else
    				tblString = tblString + table_list[i]
    		}
    		return tblString
    	},
    	getOutputColumns(columns,allsteps){
    		var colString=''
    		for (var i = 0; i < columns.length; i++) {
    			if(colString)
    				colString = colString+', '
    			let columnname = columns[i].column_name
    			if(columns[i].alias_name)
    				columnname = columns[i].alias_name
    			let tblname = columns[i].table_name
    			if(columns[i].is_drv_table === true || columns[i].is_drv_table==='true')
    				tblname= this.getRevisedWorkTable(allsteps,columns[i].table_name)
    			colString = colString + tblname +'.'+columnname
    		}

    		return colString
    	},
    	getFilterColumn(filter,allsteps){
    		if(filter.is_drv_table === false || filter.is_drv_table === 'false')
    			return filter.column_name
    		let colArray = filter.column_name.split('.')
    		if(colArray.length < 2)
    			return filter.column_name
    		return this.getRevisedWorkTable(allsteps,colArray[0])+'.'+colArray[1]
    	},
    	getOperatorText(operator){
    		return VALUE_TO_TEXT[operator]
    	},
    	getValueText(filter){
    		if(filter.operator === '_bet_' || filter.operator === '_nt_bet_')
    			return filter.value1 +' AND '+filter.value2
    		return filter.value1
    	},
    	getOperation(operation){
    		if(operation === false)
    			return "AND"
    		if(operation)
    			return "OR"
    		return ""
    	},
    	printObj(){
    		var printContents = document.getElementById("printdiv").innerHTML;
     		const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
     		WinPrint.document.write(printContents+'<style type="text/css"> table { width:98% !important; cellpadding:10; } #objtable th,td{ text-align: left !important; padding-left: 5px !important; height: 30px !important; } .childtbl{ margin:10px !important; } tr:nth-child(even) {background-color: white;} table, td, th { border: 1px solid black !important; } #objtble .childtbl { width:98% !important; margin:1%; } .firstSubHeader { width:10%  !important; } table td { } .bigtd{ white-space: -o-pre-wrap; word-wrap: break-word; white-space: pre-wrap; white-space: -moz-pre-wrap; white-space: -pre-wrap; }')
     		WinPrint.document.close();
				WinPrint.focus();
				WinPrint.print();
				WinPrint.close();
    	},
    	getObject(data){
		let _this = this
		data["permission"] = "R"
		data['check_security'] = true
		data["check_action_security"] = data.specialallowance==='y'?false:true
		Object.assign(data,{"user_id":this.$session.get("user_id"),"user_name":this.$session.get("email")})
		_this.loader = true
		let url = config.PUBLISHER_URL+'/published_object_details'
  		postToServer(_this, url, data).then(Response => {
  			let pdobject = Response
  			_this.policyDetails = data.policy_date
  			if(data.isfromjob){
  				pdobject = Response.process_definition
  				_this.selectedBos = Response.business_object_details
  			}
            let graph = new dia.Graph({},{ cellNamespace: shapes })
            let cells = graphObj.unzipGraph(_this,pdobject.object_json,'process_designer')
            let validCells = cells.cells.filter((obj)=>{
            	return obj.prop('edc_cell_type')!='process_designer_step_container'
            })
	    		graph.fromJSON(JSON.parse(JSON.stringify({"cells":validCells})));
	    	let rootEle =graph.getSources()[0]
	    	let element_list = graphObj.generateGraphObj(graph,rootEle,[rootEle.attributes])
	    	pdobject.object_json.cells = element_list
	    	this.pdjson = pdobject
    		this.manageJoins()
  			_this.loader = false

        }).catch(objError => {
        	_this.loader = false
        })

    	},
		closeTab(){
			window.top.close()
		}
    }
  }
</script>
<style scoped>
/* Whatever change made here, need to reflect in print function css */
table {
	width:98% !important;
	cellpadding:10;
}
#objtable th,td{
  text-align: left !important;
  padding-left: 5px !important;
  height: 30px !important;
}
.childtbl{
	margin:10px !important;
}
tr:nth-child(even) {background-color: white;}

table, td, th {
  border: 1px solid black !important;
}

#objtble .childtbl {
	width:98% !important; 
	margin:1%;
}

.firstSubHeader {
	width:10%  !important;
}

table td {
   
}

.bigtd{
	white-space: -o-pre-wrap; 
    word-wrap: break-word;
    white-space: pre-wrap; 
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap; 

}
</style>
