<template>
	<div>
		<v-card class="rounded-card">
			<v-toolbar dark dense>
            <v-flex class="text-md-center">Object Details
                <v-icon class="text-lg-left" @click="closeDetailPopup" style="color:#dedede;height:22px;float:right">
                    fa-times-circle</v-icon>
            </v-flex>
        </v-toolbar>
        <v-card-text>
        	<v-flex xs12 style="background-color:white">
                        <v-flex xs12>
                          <v-data-table border="0px"
                          :items="details"
                          class="elevation-1" dense
                          hide-default-header hide-default-footer>
                      		<template v-slot:body="{ items }">
                        		<tbody>
                        			<tr v-for="(item,index) in items" :key="index+'details'">
                            <td class="text-md-left" width="200px">{{ item.name }}</td>
                            <td class="text-md-left">{{ item.value }}</td>
                          </tr>
                          </tbody>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-flex>
        </v-card-text>
		</v-card>
	</div>
</template>
<script>
	import _ from 'lodash'
	import config from '../../config.json'
	import { post as postToServer } from './../../methods/serverCall.js';
	export default {
		name:"ObjectDetailpopUp",
	    data () {
	        return {
	        	details:[]
	        }
	    },
	    props:{objectdetails:Object},
	    watch:{
	    	objectdetails(newvalue){
	    		this.getObjectDetails(newvalue)
	    	}
	    },
	    methods:{
	    	getObjectDetails(object_details){
				var _this = this;
	    		if(!object_details || !object_details.object_id)
	    			return false
	    		var input_data = {"object_id":object_details.object_id,"object_revision":object_details.object_revision,"from_designer":true,"permission": "R"}
				Object.assign(input_data,{"user_id":this.$session.get("user_id"),"user_name":this.$session.get("email")})

				postToServer(this, config.REPOSITORY_API_URL + '/fetch_object_details', input_data).then(response  => {
	      			if(response){
	      				var unordered_data = []
	      				// we need to show this reposponse in tabular format. So need to create list first. Also It should be in specific order, so thats why added 'index' key
	      				if(response.added_by)
	      					unordered_data.push({"name":"Created By","value":response.added_by,"index":1})
	      				if(response.timezone_aware_added_on_date)
	      					unordered_data.push({"name":"Created Date","value":response.timezone_aware_added_on_date,"index":2})
	      				if(response.timezone_aware_added_on_time)
	      					unordered_data.push({"name":"Created Time","value":response.timezone_aware_added_on_time,"index":3})
	      				if(response.modified_by)
	      					unordered_data.push({"name":"Modified By","value":response.modified_by,"index":4})
	      				if(response.timezone_aware_modified_on_date)
	      					unordered_data.push({"name":"Modified Date","value":response.timezone_aware_modified_on_date,"index":5})
	      				if(response.timezone_aware_modified_on_time)
	      					unordered_data.push({"name":"Modified Time","value":response.timezone_aware_modified_on_time,"index":6})

	      				this.details = _.sortBy(unordered_data,"index")
	      			}
	      		}).catch(error_response => {
					_this.tableListLoader = false;
					_this.is_error_occured = true;
					alert(error_response)


	      		})
	    	},
	    	closeDetailPopup(){
	    		this.$emit("closeDetailPopup")
	    	}
	    }
	}
</script>